export const About = (props) => {
  return (
    <div id="about" style={{backgroundColor:'#162737'}}>
      <div className="container">
        <div className="text-center">
         
          <div className="">
            <div className="about-text">
              <h2>About Us</h2>
              <div style={{textAlign:'justify'}}>
              <p>We are an engineering team with a profound understanding of the challenges faced by Machine Learning practitioners across different industries.</p>
              <p>We’ve been there ourselves. We’ve trained and deployed models for all sorts of applications. From text analysis to medical image processing. From process optimisation to robotic control. </p>
              <p>The harsh reality is that developing algorithms and deploying them for maximum impact are two different beasts. Often times regulation or bandwidth limitations necessitates that the code
                 moves to the data instead of the other way around. Setting up the infrastructure to enable rapid development-testing-deployment iterations is key. </p>
              <p>We craft the tools that enable APIs and complex AI pipelines to click together. We enable code and data to pace gracefully back and forth 
                 along the cloud-edge continuum. We empower ML practitioners to transverse the AI plexus with efficiency and elegance.</p>   
                 </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
