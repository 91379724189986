import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import image1 from "../images/Picture1.png"
import image2 from "../images/Picture2.png"
import image3 from "../images/Picture3.png"
import image4 from "../images/Picture4.png"
import image5 from "../images/Picture5.png"
import image6 from "../images/Picture6.png"
import image7 from "../images/Picture7.png"
import image8 from "../images/Picture8.png"
import image9 from "../images/Picture9.png"
import image10 from "../images/Picture10.png"
import image11 from "../images/Picture11.png"
import image12 from "../images/Picture12.png"
import image13 from "../images/Picture13.png"

export default class MultipleItems extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide:1
          }
        },
      ]
    };
    return (
      <div>
        <h2 style={{color:'#162737'}}> Technologies </h2>
        <Slider {...settings}>
          <div>
            <img src={image1} id='photo'  style={{marginTop:'14px'}}></img>
          </div>
          <div>
          <img src={image2} id='photo' style={{marginTop:'-24px',marginLeft:'30px'}}></img>
          </div>
          <div>
          <img src={image3} id='photo' ></img>
          </div>
          <div>
          <img src={image4} id='photo'  style={{marginTop:'-24px'}}></img>
          </div>
          <div>
          <img src={image5} id='photo'  style={{marginTop:'18px'}}></img>
          </div>
          <div>
          <img src={image6} id='photo'  style={{marginTop:'10px',width:'300px'}}></img>
          </div>
          <div>
          <img src={image7} id='photo' style={{marginTop:'-5px',width:'194px'}}></img>
          </div>
          <div>
          <img src={image8} id='photo' style={{marginTop:'24px',width:'300px'}}></img>
          </div>
          <div>
          <img src={image9} id='photo'  style={{marginTop:'34px'}}></img>
          </div>
          <div>
          <img src={image10} id='photo'  style={{marginTop:'20px'}}></img>
          </div>
          <div>
          <img src={image11} id='photo'></img>
          </div>
          <div>
          <img src={image12} id='photo' style={{marginTop:'4px'}}></img>
          </div>
          <div>
          <img src={image13} id='photo' style={{marginTop:'-24px',width:'220px'}}></img>
          </div>
        </Slider>
      </div>
    );
  }
}


