import image2 from "../images/1.png"
import image3 from "../images/2.png"
import image6 from "../images/3.png"
import image4 from "../images/4.png"
import image5 from "../images/5.png"



export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2 style={{color:'#a3c2e4'}}>Applications</h2>
        
        </div>
        <div className='row'>
         
                <div  className='col-md-4'>
                  {' '}
                  <div id='fixit' style={{backgroundColor:'#25394e',paddingTop:'10px',paddingBottom:'10px',borderRadius:'10px',margin:'0px'}}>
                  <h3>Space</h3>
                  <i><img src={image2}></img></i>
                  <div className='service-desc'>
                    
                    <p style={{fontSize:'18px'}}>PLAIXUS AIstronaut is a platform that enables the deployment and monitoring of 
                    Edge AI Applications in Satellites, harnessing the power of the Cloud and the timeliness of the Edge. The platform utilizes 
                    well-established frameworks such as Nanosat MO Framework of ESA, KubeEdge, EdgeX and KubeFlow.</p>
                  </div>
                  </div>
                </div>

                <div  className='col-md-4'>
                  <div id='fixit' style={{backgroundColor:'#25394e',paddingTop:'10px',paddingBottom:'10px',borderRadius:'10px'}}>
                  {' '}
                  <h3>Robotics</h3>
                  <i><img src={image3}></img></i>
                  <div className='service-desc'>
                    <p style={{fontSize:'18px'}}>PLAIXUS RoboSense enables the development of AI algorithms in the Cloud and the automatic deployment 
                    of AI pipelines in the configured Robot at the Edge, It incorporates cutting edge AI, Cloud Native, Edge and Robotic frameworks such as KubeEdge, EdgeX, Akraino, Argo, KubeFlow and ROS/2</p>
                  </div>
                  </div>
                </div>

                <div  className='col-md-4'>
                  {' '}
                  <div id='fixit' style={{backgroundColor:'#25394e',paddingTop:'10px',paddingBottom:'10px',borderRadius:'10px'}}>
                  <h3>Energy</h3>
                  <i><img src={image6}></img></i>
                  <div className='service-desc'>
                    <p style={{fontSize:'18px'}}>PLAIXUS Helianthus enables the use of Smart Home data streams for training federated AI models. 
                    It is built upon popular frameworks such as Home Assistant, openHAB and HomeEdge. It can be extended for DSOs to DSOs to combine data from substations and RTUs and perform optimized grid utilization and predictive maintenance to their network</p>
                  </div>
                  </div>
                </div>
    <div id='app2' style={{textAlign:'center'}}>
                  <div  className='col-md-4'>
                  {' '}
                  <div id='fixit' style={{backgroundColor:'#25394e',paddingTop:'10px',paddingBottom:'10px',borderRadius:'10px'}}>
                  <h3>Industry</h3>
                  <i><img src={image4}></img></i>
                  <div className='service-desc'>
                    <p style={{fontSize:'18px'}}>PLAIXUS Pledge integrates IIoT with the cloud and existing  DCS, PLC and SCADA. Pledge reduces fragmentation issues when building IIoT and AI applications,
                     eliminating the data silos often found in plants and factories. The platform utilizes well- established frameworks such as KubeEdge, EdgeX and Fledge.</p>
                  </div>
                  </div>
                </div>
                <div  className='col-md-4'>
                  {' '}
                  <div id='fixit' style={{backgroundColor:'#25394e',paddingTop:'10px',paddingBottom:'10px',borderRadius:'10px'}}>
                  <h3>Healthcare</h3>
                  <i><img src={image5}></img></i>
                  <div className='service-desc'>
                    <p style={{fontSize:'18px'}}>PLAIXUS FederatAId enables the use of multiple HealthCare data repositories to train federated AI models in full compliance with data privacy regulations 
                    utilizing popular Federated AI and Cloud Native Frameworks such as Kubeflow, KubeEdge, TensorFlow Federated and PySyft.</p>
                  </div>
                  </div>
                </div>
                </div>  
        </div>
      </div>
    </div>
  )
}
