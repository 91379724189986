export const Whatwedo = (props) => {
  return (
    <div id="whatwedo" style={{backgroundColor:'#25394e'}}>
      <div className="container">
        <div className="text-center">
         
          <div className="">
            <div className="about-text">
              <h2>What we do</h2>
              <br/>
              <div style={{textAlign:'justify'}}>
              <p>We specialize in fine tuning MLOps for a vastly diverse range of applications leveraging Natural Language Processing, Computer Vision and Data Analysis.</p>
              <p>Our team has mastered complex Machine Learning lifecycles encompassing such as data ingest, data prep, model training, model tuning, model deployment, model monitoring, explainability, and much more. </p>
              <p>Our tools are highly customisable and are built to maximise efficiency, scalability and compliance. They enable data teams to accelerate model development and deployment while achieving higher quality. 
                They facilitate supervision, monitoring and control of multiple models nodes. They allow for complete transparency and tight conformance to regulations involving data security and privacy.</p>  
                 </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
