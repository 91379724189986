import image7 from "../images/MouldingStation.png"
import image5 from "../images/ControlBox.png"
import MultipleItems from "./carousel"

export const Features = (props) => {

  return (
    <div id='features' style={{padding: "100px 0"}}className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
        
        </div>
        <MultipleItems/>
      
        
      </div>
     
    </div>
  )
}
